import React from "react";
import Logo from "../home/logo";
import Social from "../Social";
import "./About.css";
const About = () => {
  return (
    <div>
      <div className="heroContainer">
        <Logo />
        <div className="hero">
          <div className="welcomNote">
            <h1 className="hero2">About Us</h1>
          </div>
        </div>
      </div>
      <section className="s1">
        {" "}
        <h2> Meneye Monday</h2>
        <img src="images/monday.jpg" width="15%" alt="" />
      </section>
      <div
        className="flex"
        style={{ backgroundColor: "white", paddingTop: "2rem" }}
      >
        <a href="https://wa.me/message/YLOK7D7K3PQ2L1">
          {" "}
          <h1 style={{ color: "green" }}>
            <p> Message Us directly through our WhatsApp</p>
            <i className="fa-brands fa-whatsapp"></i>
          </h1>
        </a>{" "}
        <a href="https://t.me/Meyeneelectricalcompany">
          {" "}
          <h1 style={{ color: "green" }}>
            <p> Or our Telegram</p>
            <i className="fa-brands fa-telegram"></i>
          </h1>
        </a>{" "}
      </div>
      <section className="s2">
        <p>
          When it comes to all the related services listed in our service page,
          Meyene Electrical Company is one of the best. We install different
          capacities of inverters and solar arrays, this is done by our highly
          skilled and experienced technicians and engineers. Here, we give
          priority to safety and efficiency.
        </p>
        <p>
          {" "}
          Over the years, we have delivered efficient and durable services to
          several homes and offices in Nigeria and beyound. Thanks to the
          positive feed back we get from our clients, this gives us the drive to
          do better and serve you better.
        </p>
        <p>
          Meyene electrical have its headquarter situated at Akwa Ibom State,
          Aka Road No: 14. Meyene electrical company also have their branch
          offices in the following states Abuja, Nasaraw, Rivers State(Port
          Harcourt), Bayelsa and One of our major office is also located in
          Lagos At NO: 4 Oloruntegbe Street, Ayeteju, Ibeju-Lekki, Lagos State.
          You can message us directly through our WhatsApp contact provided You
          can also call us directly through the phone number provided Email us
          through: meyeneelectricalcompany @gmail.com
        </p>
      </section>

      <Social />
    </div>
  );
};

export default About;
