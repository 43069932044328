import React from "react";
import "./Home.css";
import Hero from "./hero";
import Logo from "./logo";
// import Slider from "../pages/Heroslider";
import Social from "../Social";
import { Link } from "react-router-dom";
import video from "../video/vid1.mp4";
import ebook from "../asset/ebook1.jpeg";
import ebook2 from "../asset/ebook2.jpeg";
import ebook4 from "../asset/ebook4.jpeg";
import eboo3 from "../asset/ebook3.jpeg";
import elect1 from "../asset/elect1.jpeg";
import elect2 from "../asset/elect3.jpeg";
import elect3 from "../asset/elect2.jpeg";
import elect4 from "../asset/elect4.jpeg";
import elect5 from "../asset/elect5.jpeg";
import elect6 from "../asset/elect6.jpeg";
import elect7 from "../asset/elect7.jpeg";
import elect8 from "../asset/elect8.jpeg";

const Home = () => {
  return (
    <React.Fragment>
      <main className="container heroContainer">
        <div className="herobg">
          <Hero />
        </div>

        <div className="animate">
          <p>Meyene Electrical Company by JoelionWebDev</p>
        </div>
        {/* <div>
          <Slider />
        </div> */}
        <div
          className="flex"
          style={{ backgroundColor: "white", paddingTop: "2rem" }}
        >
          <a href="https://wa.me/message/YLOK7D7K3PQ2L1">
            {" "}
            <h1 style={{ color: "green" }}>
              <p> Message Us directly through our WhatsApp</p>
              <i className="fa-brands fa-whatsapp"></i>
            </h1>
          </a>{" "}
          <a href="https://t.me/Meyeneelectricalcompany">
            {" "}
            <h1 style={{ color: "green" }}>
              <p> Or our Telegram</p>
              <i className="fa-brands fa-telegram"></i>
            </h1>
          </a>{" "}
        </div>

        <div className="home-body">
          <p>
            In the world of increasing fuel prices, having a renewable energy
            source is the best alternative. This is better achieved with MEYENE
            ELECTRICAL services because we give priority to durability, safety
            and efficiency giving you value for your money. In MEYENE ELECTRICAL
            COMPANY,We offer numerous services that have to do with Installation
            of several electrical gadget. Check out our
            <Link to="/Services" className="big-link">
              {" "}
              services{" "}
            </Link>
            page to get more information about the services we offer.
          </p>
          <p>
            Over the years we have provided several electrical installations as
            well as efficient and durable renewable energy to several homes and
            offices in Nigeria and beyond, using solar and inverters system.
            Thanks to the positive feed back we get from our clients, this gives
            us the drive to do better and serve you better.
          </p>

          <h1 className="meye">MEYENE ELECTRICAL</h1>
          <div className="flex flex1">
            <img src="/images/contract img.jpeg" alt="" />
            <div className="text">
              <h3>
                Meyene Electrical Company <br />
              </h3>{" "}
              <p>
                Meyene electrical have its headquarter situated at Akwa Ibom
                State, Aka Road No: 14. <br />
                Meyene electrical company also have their branch offices in the
                following states Abuja, Nasaraw, Rivers State(Port Harcourt),
                Bayelsa and One of our major office is also located in Lagos At
                NO: 4 Oloruntegbe Street, Ayeteju, Ibeju-Lekki, Lagos State.
              </p>
              <ul className="headquarter">
                <li>
                  You can message us directly through our WhatsApp contact
                  provided
                </li>
                <li>
                  You can also call us directly through the phone number
                  provided
                </li>

                <li>
                  <h5> Email us through: </h5>{" "}
                  <span>
                    {" "}
                    meyeneelectricalcompany <br /> @gmail.com
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <video
            className="video"
            style={{ backgroundColor: "black" }}
            width="750"
            height="600"
            controls
          >
            <source src={video} type="video/mp4" />
          </video>

          <section className="section2">
            <h2>Our work quality</h2>
            <p>
              We are one of the so many Electrical and Solar Installation
              Companies in the world you can rely on for an outstanding neat and
              exceptional work output. Take your time to go through our site and
              get fed of almost all the information you need about us. You can
              equally reach us for more information.
            </p>
            <div className="flex flex2">
              <div className="img1">
                <h4 className="img-text">
                  Installation of Telecommunication wifi
                </h4>
                <img src="images/telecom wifi.jpg" alt="" />
              </div>
              <div className="img1">
                <h4 className="img-text">Installation of Led moving light</h4>
                <img src="/images/led moving light.jpg" alt="" />
              </div>
            </div>
          </section>

          <section className="s2">
            <div className="flex flex2">
              <div className="img1">
                <img src={ebook} alt="" />
              </div>
              <div className="img1">
                <img src={ebook2} alt="" />
              </div>
            </div>
          </section>
          <section className="s2">
            <div className="flex flex2">
              <div className="img1">
                <img src={eboo3} alt="" />
              </div>
              <div className="img1">
                <img src={ebook4} alt="" />
              </div>
            </div>
          </section>
          <section className="s2">
            <img src="/images/work.jpg" width="70%" alt="" />
          </section>
          <section className="s2">
            <div className="flex flex2">
              <div className="img1">
                <img src={elect1} alt="" />
              </div>
              <div className="img1">
                <img src={elect2} alt="" />
              </div>
            </div>
          </section>
          <section className="s2">
            <div className="flex flex2">
              <div className="img1">
                <img src={elect3} alt="" />
              </div>
              <div className="img1">
                <img src={elect4} alt="" />
              </div>
            </div>
          </section>
          <section className="s2">
            <div className="flex flex2">
              <div className="img1">
                <img src={elect5} alt="" />
              </div>
              <div className="img1">
                <img src={elect6} alt="" />
              </div>
            </div>
          </section>
          <section className="s2">
            <div className="flex flex2">
              <div className="img1">
                <img src={elect7} alt="" />
              </div>
              <div className="img1">
                <img src={elect8} alt="" />
              </div>
            </div>
          </section>

          <section className="s2">
            <h5>
              You can reach us from anywhere in the world, Do good to reach us{" "}
              <br /> through any of our social media handles
            </h5>
            <img src="/images/anywhere.jpg" width="50%" alt="" />
          </section>
          <section className="s2">
            <img src="/images/monday.jpg" width="70%" alt="" />
          </section>
        </div>
        <Social />
      </main>
    </React.Fragment>
  );
};

export default Home;
