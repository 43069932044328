import React, { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Sliderdata } from "../herosliderdata";
import "./heroslider.css";

const Slider = () => {
  const [people, setPeople] = useState(Sliderdata);
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);
  return (
    <main>
      <section className="sectionn">
        <div className="section-centern">
          {people.map((person, personIndex) => {
            const { id, image, title } = person;

            let position = "nextSlide";
            if (personIndex === index) {
              position = "activeSlide";
            }
            if (
              personIndex === index - 1 ||
              (index === 0 && personIndex === people.length - 1)
            ) {
              position = "lastSlide";
            }

            return (
              <article className={position} key={id}>
                <img src={image} alt={title} className="person-imgn" />
              </article>
            );
          })}
          <h4 className="qualityn">We deliver qaulity work</h4>
          <button className="prev" onClick={() => setIndex(index - 1)}>
            <FiChevronLeft />
          </button>
          <button className="next" onClick={() => setIndex(index + 1)}>
            <FiChevronRight />
          </button>
        </div>
      </section>
    </main>
  );
};

export default Slider;

// "use client";
// import React from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// // Import Swiper styles
// import "swiper/css";
// //implementing Autoplay
// import {
//   Autoplay,
//   //   Pagination,
//   //   Scrollbar,
//   //   Navigation,
//   //   A11y,
// } from "swiper/modules";

// export default function Slider() {
//   return (
//     <Swiper
//       //   modules={[Autoplay, Pagination, Scrollbar, Navigation, A11y]}
//       modules={[Autoplay]}
//       spaceBetween={50}
//       //   navigation
//       //   pagination={{ clickable: true }}
//       //   scrollbar={{ draggable: true }}
//       slidesPerView={1}
//       autoplay={{
//         delay: 2000,
//       }}

//       //   onSlideChange={() => console.log("slide change")}
//       //   onSwiper={(swiper) => console.log(swiper)}
//     >
//       <SwiperSlide>
//         <img src="images/slider/a1.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a2.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a3.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a4.png" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a5.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a6.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a7.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a8.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a9.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a10.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a11.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a12.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a13.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a14.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a15.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a16.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a17.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a18.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a19.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a20.jpeg" alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="images/slider/a1.jpeg" alt="" />
//       </SwiperSlide>
//       ...
//     </Swiper>
//   );
// }
