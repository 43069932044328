import React from "react";
import "./Home.css";
const logo = () => {
  return (
    <div>
      <img className="logo" src="/images/meni.jpg" alt="logo" />
    </div>
  );
};

export default logo;
