import React from "react";
import { social } from "./data";

const Social = () => {
  return (
    <main>
      <div className="social-icon">
        <div className="contact">
          <h3 style={{ color: "white" }}>07035836607</h3>
        </div>
        <ul className="social-icons">
          {social.map((socialIcon) => {
            const { id, url, icon, name } = socialIcon;
            return (
              <li key={id}>
                <h4 style={{ paddingLeft: ".5rem", paddingTop: ".5rem" }}>
                  <a href={url}>
                    {icon} {name}
                  </a>
                </h4>
              </li>
            );
          })}
        </ul>
        <p style={{ color: "white" }}>
          Meyene electrical have its headquarter situated at Akwa Ibom State,
          Aka Road No: 14.
        </p>
      </div>
    </main>
  );
};

export default Social;
