import React from "react";

export const Sliderdata = [
  {
    id: 1,
    image: "images/slider/a1.jpeg",
    title: "Our latest site work",
  },
  {
    id: 2,
    image: "images/slider/a2.jpeg",
    title: "Our latest site work",
  },
  {
    id: 3,
    image: "images/slider/a3.jpeg",
    title: "Our latest site work",
  },
  {
    id: 4,
    image: "images/slider/a4.jpeg",
    title: "Our latest site work",
  },
  {
    id: 5,
    image: "images/slider/a5.jpeg",
    title: "Our latest site work",
  },
  {
    id: 6,
    image: "images/slider/a6.jpeg",
    title: "Our latest site work",
  },
  {
    id: 7,
    image: "images/slider/a7.jpeg",
    title: "Our latest site work",
  },
  {
    id: 8,
    image: "images/slider/a8.jpeg",
    title: "Our latest site work",
  },
  {
    id: 9,
    image: "images/slider/a9.jpeg",
    title: "Our latest site work",
  },
  {
    id: 10,
    image: "images/slider/a10.jpeg",
    title: "Our latest site work",
  },
  {
    id: 11,
    image: "images/slider/a11.jpeg",
    title: "Our latest site work",
  },
  {
    id: 12,
    image: "images/slider/a12.jpeg",
    title: "Our latest site work",
  },
  {
    id: 13,
    image: "images/slider/a13.jpeg",
    title: "Our latest site work",
  },
  {
    id: 14,
    image: "images/slider/a14.jpeg",
    title: "Our latest site work",
  },
  {
    id: 15,
    image: "images/slider/a15.jpeg",
    title: "Our latest site work",
  },
  {
    id: 16,
    image: "images/slider/a16.jpeg",
    title: "Our latest site work",
  },
  {
    id: 17,
    image: "images/slider/a17.jpeg",
    title: "Our latest site work",
  },
  {
    id: 18,
    image: "images/slider/a18.jpeg",
    title: "Our latest site work",
  },
  {
    id: 19,
    image: "images/slider/i1.jpeg",
    title: "Our latest site work",
  },
  {
    id: 20,
    image: "images/slider/a20.jpeg",
    title: "Our latest site work",
  },
  {
    id: 21,
    image: "images/slider/i2.jpeg",
    title: "Our latest site work",
  },
  {
    id: 22,
    image: "images/slider/i3.jpeg",
    title: "Our latest site work",
  },
  {
    id: 23,
    image: "images/slider/i4.jpeg",
    title: "Our latest site work",
  },
  {
    id: 24,
    image: "images/slider/i6.jpeg",
    title: "Our latest site work",
  },
  {
    id: 25,
    image: "images/slider/i7.jpeg",
    title: "Our latest site work",
  },
  {
    id: 26,
    image: "images/slider/i8.jpeg",
    title: "Our latest site work",
  },
  {
    id: 27,
    image: "images/slider/i9.jpeg",
    title: "Our latest site work",
  },
  {
    id: 28,
    image: "images/slider/i10.jpeg",
    title: "Our latest site work",
  },
  {
    id: 29,
    image: "images/slider/i10s.jpeg",
    title: "Our latest site work",
  },
  {
    id: 30,
    image: "images/slider/is5.jpeg",
    title: "Our latest site work",
  },
];
