import React from "react";
import HeroSlider from "../pages/Heroslider";

const Hero = () => {
  return (
    <React.Fragment>
      {/* <main className="hero">
        <div className="welcomNote">
        <h1 className="hero2">
        MEYENE ELECTRICAL
        <br />
        <span>COMPANY</span>
          </h1>
        </div>
      </main> */}
      <HeroSlider />
    </React.Fragment>
  );
};

export default Hero;
